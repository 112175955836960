// Imports
import styles from '../css/app.scss';
import noScroll from 'no-scroll';
import throttle from 'lodash.throttle';
import picturefill from 'picturefill';
import svg4everybody from 'svg4everybody';
import IntersectionObserverPoly from 'intersection-observer';
import babelPolyfill from '@babel/polyfill';

//
// App async loading modules
// These modules are only loaded when needed/found
//
const main = async () => {
  // Forms
  let formEls = [...document.querySelectorAll('.Form')];
  if (formEls.length) {
    const Forms = await import(/* webpackChunkName: "forms" */ './components/Forms');
  }
  // Slideshow
  let slideshowEl = document.getElementById('HouseSlideshow');
  if (slideshowEl) {
    const SlideShow = await import(/* webpackChunkName: "slideshow" */ './components/Slideshow');
  }
  // Floorplans navigations
  let houseFloorplans = document.getElementById('HouseFloorplans');
  if (houseFloorplans) {
    const { FloorplanNav } = await import(/* webpackChunkName: "floorplanNav" */ './components/Floorplan-nav');
    FloorplanNav.init();
  }
  // Forms
  let modalEls = [...document.querySelectorAll('.Modal')];
  if (modalEls.length) {
    const { Modals } = await import(/* webpackChunkName: "floorplanNav" */ './components/Modals');
    Modals.init();
  }

};

//
// Application
//
const Ostunet = {
  // Options / globals
  lastScrolltop: 0,
  headerIsHidden: false,
  headerElement: null,
  frontIntroElement: null,

  init () {

    // Init picturefill poly
    picturefill();
    // Init svg4everybody poly
    svg4everybody();

    // Load async modules
    main().then( () => {
      //console.log('Modules loaded');
    });

    // header element
    this.headerElement = document.getElementById('Header');

    // Burger menu noscroll toggle
    this.burgerMenubtn = document.getElementById('NavTrigger');
    if (this.burgerMenubtn) {
      this.burgerMenubtn.addEventListener('click', this.toggleNoScroll);
    }

    // Frontpage header bg change on introduction intersection
    if ( document.body.classList.contains('page-template-frontpage') ) {
      window.addEventListener('scroll', throttle(this.frontpageHeaderOverlap, 1000 / 60));
      this.frontIntroElement = document.getElementById('Intro');
    }

    // Init swipe in images on scroll
    if ( document.querySelector('.WillSwipe') ) {
      this.observeImages();
    }

    let houseSelectorPresent = document.querySelector('.HouseSelector__wrapper');
    if (houseSelectorPresent) {
      this.displayIEWarning();
    }

    // On scroll
    window.addEventListener('scroll', throttle(this.onScroll, 1000 / 60));
    window.addEventListener('scroll', throttle(this.scrollDelayed, 200));
  },

  initOnLoad () {
    console.log('Made with love by tada.no');
    console.log('Developed by Henrik H. Boelsmand');
  },

  onScroll () {
    const vp = window.pageYOffset || document.documentElement.scrollTop;
    if (!Ostunet.headerIsHidden && vp > 5 && vp > Ostunet.lastScrolltop + 30) {
      // Hide header on scroll down
      Ostunet.headerElement.classList.add('Header--hidden');
      Ostunet.headerIsHidden = true;
    } else if (Ostunet.headerIsHidden && vp < Ostunet.lastScrolltop - 30) {
      // Show header on scroll up
      Ostunet.headerElement.classList.remove('Header--hidden');
      Ostunet.headerIsHidden = false;
    }
  },

  scrollDelayed () {
    Ostunet.lastScrolltop = window.pageYOffset || document.documentElement.scrollTop;
  },

  toggleNoScroll () {
    noScroll.toggle();
  },

  frontpageHeaderOverlap() {
    let overlapping = Ostunet.isOverlapping(Ostunet.headerElement, Ostunet.frontIntroElement);
    if( overlapping ) {
      Ostunet.headerElement.classList.add('Header--frontpage--bg');
    } else {
      if ( Ostunet.headerElement.classList.contains('Header--frontpage--bg') ) {
        Ostunet.headerElement.classList.remove('Header--frontpage--bg');
      }
    }
  },

  displayIEWarning() {
    let version = this.detectIE();

    if (version === false) {
      // Not explorer
    } else if (version >= 12) {
      // Edge
    } else {
      // Internet explorer
      var houseSelector = document.querySelector('.HouseSelector__wrapper');
      houseSelector.classList.add('HouseSelector--InternetExplorer');
    }
  },

  observeImages () {

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8
    }

    if (typeof IntersectionObserver !== "undefined") {
      // Observer API exists

      let observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if ( entry.isIntersecting ) {
            entry.target.classList.add('SwipeIn');
            observer.unobserve( entry.target );
          }
        });
      }, options);
  
      const images = Array.from( document.querySelectorAll('main .WillSwipe') );
  
      images.forEach(image => {
        observer.observe(image);
      });
      
    } else {
      let observer = new IntersectionObserverPoly((entries) => {
        entries.forEach(entry => {
          if ( entry.isIntersecting ) {
            entry.target.classList.add('SwipeIn');
            observer.unobserve( entry.target );
          }
        });
      }, options);
  
      const images = Array.from( document.querySelectorAll('main .WillSwipe') );
  
      images.forEach(image => {
        observer.observe(image);
      });
    }

  },

  /**
   * isOverlapping() returns the overlapping status between two elements
   * based on the passed in Element objects
   *
   * @param {Element, Element} Element object of DOM
   * @return {Boolean|null} overlap status or null if native objet not received
   */
  isOverlapping(e1, e2) {
    if( e1.length && e1.length > 1 ){
      e1 = e1[0];
    }
    if( e2.length && e2.length > 1 ){
      e2 = e2[0];
    }
    var rect1 = e1 instanceof Element ? e1.getBoundingClientRect() : false;
    var rect2 = e2 instanceof Element ? e2.getBoundingClientRect() : false;
    
    //window.console ? console.log(rect1, rect2 ) : null ;
    var overlap = null;
    if( rect1 && rect2 ){
      overlap = !(
          rect1.right < rect2.left || 
          rect1.left > rect2.right || 
          rect1.bottom < rect2.top || 
          rect1.top > rect2.bottom
        )
      return overlap;  
    } else {
      window.console ? console.warn( 'Please pass native Element object' ) : null;
      return overlap;
    }
  },

  detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      var rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }

};

document.addEventListener('DOMContentLoaded', () => Ostunet.init());
window.addEventListener('load', () => Ostunet.initOnLoad());